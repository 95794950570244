<template>
  <section
    class="overflow-hidden"
  >
    <v-row
      class="secondary"
      no-gutters
    >
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/whitewallladder960x1080.jpg')"
          height="100%"
        />
      </v-col>

      <v-col
        class="pa-5"
        cols="12"
        md="6"
      >
        <base-bubble-1 />

        <base-heading class="mb-5">
          Get In Touch
        </base-heading>

        <v-sheet
          color="transparent"
          max-width="600"
        >
        <form
          name="inTouchForm"
          method="post"
          data-netlify="true"
          data-netlify-honeypot
        >
          <input
            type="hidden"
            name="form-name"
            value="inTouchForm"
          />
          <v-text-field
            color="secondary"
            name="name"
            label="Name"
            solo
            flat
          />
            <p hidden>
                <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
            </p>
          <v-text-field
            color="secondary"
            label="Email"
            name="email"
            solo
            flat
          />

          <v-text-field
            color="secondary"
            label="Subject"
            name="subject"
            solo
            flat
          />

          <v-textarea
            color="secondary"
            label="Message"
            name="message"
            solo
            flat
          />

          <button
            id="formSubmitBtn"
            type="submit"
            >
            Send
          </button>
        </form>
        </v-sheet>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'GetInTouchForm',
    data () {
      return {
        form: {
          inTouchForm: '',
        },
      }
    },
    methods: {
      encode (data) {
        return Object.Keys(data)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&')
      },
      submitIt () {
        const axiosConfig = {
          header: { 'Contenct-Type': 'application/x-www-form-urlencoded' },
        }
        axios.post(
          '/',
          this.encode({
            'form-name': 'inTouchForm',
            ...this.form,
          }),
          axiosConfig,
        )
      },
    },
  }
</script>

<style scoped>
#formSubmitBtn {
    color: secondary;
}
</style>
